/*------------------------------------------
    row-box
------------------------------------------*/
@use "../global" as g;

.row-box {
    $this: '.row-box';

    margin: 60px 0;

    @include g.mq('md') {
        margin: 95px 0 137px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;

        @include g.mq('md') {
            margin-top: 165px;
            display: flex;
            flex-wrap: wrap;
            gap: 114px;
        }
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        @include g.mq('md') {
            display: flex;
            justify-content: space-between;
            gap: 6.05%;
        }

        &:nth-of-type(2n-1) {
            @include g.mq('md') {
                flex-direction: row-reverse;
            }

            #{$this}__cont {
                @include g.mq('md') {
                    padding-left: 0;
                    padding-right: g.clamp_size(0,76,1340,1440);
                }
            }
        }
    }

    &__cont {
        @include g.mq('md') {
            flex: 1;
            padding-left: g.clamp_size(0,90,1340,1440);
        }
    }

    &__tit {
        font-size: 2.6rem;
        letter-spacing: .02em;
        line-height: 1.5;
        font-weight: 200;
        margin-bottom: 4px;

        @include g.mq('md') {
            font-size: 4.9rem;
            margin-bottom: 2px;
            margin-top: 33px;
        }
    }

    &__sub-tit {
        position: relative;
        font-size: 1.4rem;
        letter-spacing: .02em;
        font-weight: 200;

        @include g.mq('md') {
            font-size: 1.8rem;
        }

    }

    &__txt {
        position: relative;
        line-height: 2.125;
        letter-spacing: .1em;
        margin-top: 14px;
        padding-top: 24px;

        @include g.mq('md') {
            margin-top: 18px;
            padding-top: 28px;
        }

        &::after {
            content: "";
            display: block;
            width: 42px;
            height: 2px;
            background: g.color('key-thin');
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__pic {
        @include g.mq('md') {
            width: 47.92%;
        } 
    }

    &__annotation {
        background: #fff;
        border-radius: 11.5px;
        padding: 14px 14px 14px 20px;
        margin-top: 20px;

        @include g.mq('md') {
            padding: 18px 20px 20px 31px;
            margin-top: 20px;
        }

        &-tit {
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: .01em;
            color: g.color('key');
            margin-bottom: 4px;

            @include g.mq('md') {
                font-size: 2.0rem;
            }
        }

        &-item {
            line-height: 2.125;

            &::before {
                content: "・";
                color: g.color('key');
            }
        }
    }

}