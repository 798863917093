@use "../global" as g;

/*------------------------------------------
	footer
------------------------------------------*/
.footer{
	padding: 30px 0 20px;
	
	@include g.mq('lg') {
		padding: 80px 0 40px;
	}

	&__inner{
		width: 85%;
		max-width: 100%;
		margin: 0 auto;
		
		@include g.mq('lg') {
			width: 100%;
			max-width: 1240px;
			padding: 0 20px;
			margin: 0 auto;
		}
	}

    &__logo {
		width: 140px;
		margin-bottom: 25px;
		
		@include g.mq('lg') {
			width: 228px;
			margin-bottom: 50px;
		}
	}

	&__box{
		@include g.mq('lg') {
			display: flex;
			justify-content: space-between;
		}
	}

	/*------------------------------------------
		オフィス一覧
	------------------------------------------*/
	&__office{
		margin-bottom: 15px;

		@include g.mq('lg') {
			margin-bottom: 0;
		}

		&-list{
			display: flex;
			gap: 40px;
			
			@include g.mq('lg') {
				gap: 70px;
			}
		}

		&-name{
			position: relative;
			margin-bottom: 10px;
			padding-left: 14px;
			font-size: 1.2rem;
			font-family: g.font('tit');
			color: g.color('key');
			line-height: 1.1;
			
			@include g.mq('lg') {
				font-size: 1.9rem;
			}

			&::before{
				content: "";
				position: absolute;
				display: inline-block;
				left: 0;
				height: 100%;
				width: 4px;
				border-radius: 4px;
				background-color: g.color('key');
			}

			span{
				font-size: 1.0rem;
				font-family: g.font('base');
				
				@include g.mq('lg') {
					font-size: 1.5rem;
				}
			}
		}

		&-info{
			position: relative;
			min-height: 70px;
			padding-left: 14px;
			
			@include g.mq('lg') {
				min-height: 105px;
			}

			&::before{
				content: "";
				position: absolute;
				display: inline-block;
				left: 0;
				height: 100%;
				width: 4px;
				border-radius: 4px;
				background-color: g.color('border');
			}

			p{
				font-size: 1.0rem;
				
				@include g.mq('lg') {
					font-size: 1.5rem;
				}
			}
		}
	}

	/*------------------------------------------
		ボタン郡
	------------------------------------------*/
	&__convertion{
		&-list{
			display: flex;
			gap: 10px;
			
			@include g.mq('lg') {
				justify-content: flex-end;
				gap: 20px;
			}
		}

		&-item{
			width: 100%;

			a{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				font-size: 1.1rem;
				font-weight: bold;
				text-align: center;
				
				@include g.mq('lg') {
					width: 213px;
					height: 56px;
					font-size: 1.5rem;
				}
			}
	
			&.is-tel{
				a{
					border: 4px solid g.color('border');
					border-radius: 12px;
					color: g.color('key');
				}
			}
	
			&.is-contact{
				a{
					background-color: g.color('key');
					border-radius: 50px;
					text-align: center;
					color: #FFF;
				}
			}
		}
	}

	/*------------------------------------------
		googlemap
	------------------------------------------*/
	&__gnav{
		margin-top: 40px;
		
		@include g.mq('lg') {
			margin-top: 50px;
		}

		iframe{
			width: 100%;
			height: 160px;
			
			@include g.mq('lg') {
				height: 316px;
			}
		}
	}

	/*------------------------------------------
		最下部
	------------------------------------------*/
	&__foot{
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 30px;
		
		@include g.mq('lg') {
			flex-direction: row;
			justify-content: space-between;
			margin-top: 85px;
			margin-bottom: 0;
		}

		&-list{
			@include g.mq('lg') {
				display: flex;
			}
		}

		&-item{
			@include g.mq('lg-max') {
				text-align: center;
			}

			@include g.mq('lg') {
				&:not(:first-of-type){
					margin-left: 10px;
					
					&::before{
						content: "|";
						padding-right: 10px;
					}
				}
			}

			a{
				font-size: 1.4rem;
			}
		}
	}

	&__copyright {
		margin-top: 20px;
		font-size: 1rem;
		letter-spacing: 0.1em;
		
		@include g.mq('lg-max') {
			text-align: center;
		}
		
		@include g.mq('lg') {
			margin-top: 0;
			font-size: 1.2rem;
		}
	}
}