@use "../global" as g;
/*------------------------------------------
	tit02
------------------------------------------*/
.tit02 {
	margin-bottom: 20px;
	font-family: g.font('tit');
	font-size: 3.4rem;
	font-weight: bold;
	line-height: 1.2;
	
	@include g.mq('md') {
		margin-bottom: 35px;
		font-size: 6.7rem;
	}

	span{
		display: block;
		font-size: 1.6rem;
		color: g.color('base');
		font-family: g.font('base');
		
		@include g.mq('md') {
			font-size: 2.2rem;
		}
	}
}


