/*******************************************
    サービス
*******************************************/
@use "../global" as g;

#service {

    .sec {
        margin: 60px 0;

        @include g.mq('md') {
            margin: 147px 0 160px;
        }

        &__tit-l {
            margin-bottom: 40px;

            @include g.mq('md') {
                margin-bottom: 64px;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            width: 100%;

            @include g.mq('md') {
                gap: 54px;
            }
        }

        &__item {
            background: #fff;
            border-radius: 25px;
            font-size: 1.4rem;

            @include g.mq('md') {
                border-radius: 32px;
                font-size: 1.8rem;
            }
        }

        &__top {
            @include g.mq('md') {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                gap: g.clamp_size(38,70,768,1400);
            }
        }

        &__img {
            @include g.mq('md') {
                width: 25.29%;
            }

            img {
                border-radius: 25px 25px 0 0;
                width: 100%;

                @include g.mq('md') {
                    border-radius: 32px 0 0 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &__box {
            padding: 26px 7.5% 26px;

            @include g.mq('md') {
                flex: 1;
                padding: 60px g.clamp_size(34,60,768,1400) 50px 0;
                max-width: 870px;
            }
        }

        &__tit-area {
            @include g.mq('md') {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                margin-bottom: 12px;
            }
        }

        &__tit {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
            color: g.color('key');
            font-size: 2.0rem;
            line-height: 2;
            letter-spacing: .2em;
            font-weight: 300;
            margin-bottom: -14px;

            @include g.mq('md') {
                font-size: 3.4rem;
                gap: 14px;
                line-height: 1.2;
                margin-bottom: 0;
            }

            &::before {
                content: "";
                display: block;
                width: 47px;
                height: 50px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;

                @include g.mq('md') {
                    width: 64px;
                    height: 67px;
                    margin-top: -20px;
                }
            }

            &.is-icon01 {
                &::before {
                    background-image: url(/assets/img/service/icon01.svg);
                }
            }

            &.is-icon02 {
                &::before {
                    background-image: url(/assets/img/service/icon02.svg);
                }
            }

            &.is-icon03 {
                &::before {
                    background-image: url(/assets/img/service/icon03.svg);
                }
            }

            &.is-icon04 {
                &::before {
                    background-image: url(/assets/img/service/icon04.svg);
                }
            }

            &.is-icon05 {
                &::before {
                    background-image: url(/assets/img/service/icon05.svg);
                }
            }
        }

        &__tit-s {
            color: #808080;
            letter-spacing: .04em;
            font-weight: 500;
            margin-bottom: 22px;
            padding-left: 57px;

            @include g.mq('md') {
                padding: 0;
                margin: 0;
            }
        }

        &__txt {
            line-height: 2.05;
            letter-spacing: .12em;
        }

        .example {
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: .1em;
            color: g.color('key-thick');
            padding: 30px 7.5% 18px;
            border-top: 1px solid g.color('border-tit');

            @include g.mq('md') {
                display: flex;
                align-items: flex-end;
                gap: 16px;
                font-size: 2.0rem;
                padding: 34px g.clamp_size(14,55,768,1400) 38px g.clamp_size(34,62,768,1400);
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: stretch;
                gap: 8px;

                @include g.mq('md') {
                    flex: 1;
                    gap: 18px;
                }
            }

            &__item {
                display: block;
                background: g.color('bg');
                border-radius: 6px;
                padding: 10px 20px;
                width: 100%;

                @include g.mq('md') {
                    border-radius: 13px;
                    padding: 24px 35px;
                    flex: 1;
                    display: flex;
                    align-items: center;
                }
            }

            &__txt {
                text-align: right;
                margin-top: 8px;
                padding-right: 8px;
                font-size: 1.3rem;

                @include g.mq('md') {
                    font-size: 2.0rem;
                }
            }
        }
    }

}

