/*******************************************
    トップページ
*******************************************/
@use "../global" as g;

#frontpage {

/*------------------------------------------
    mv
------------------------------------------*/
.mv {
    position: relative;
    margin-bottom: 145px;

    @include g.mq('md') {
        margin-bottom: 190px;
    }

    .inner{
        position: relative;
    }

    &__box-txt{
        position: absolute;
        top: g.get_vw(195);
        
        @include g.mq('md') {
            top: g.get_vw(280, 1920);
        }
    }

    &__tit{
        font-family: g.font('tit');
        font-size: 4.8rem;
        font-weight: bold;
        line-height: 1.0;
        margin-bottom: 10px;
        
        @include g.mq('md') {
            font-size: 9.0rem;
        }
    }

    &__txt{
        font-size: 1.3rem;
        padding-right: 80px;
        
        @include g.mq('md') {
            font-size: 1.4rem;
            padding-right: 0;
        }
    }

    /*------------------------------------------
        スクロール誘導アニメーション
    ------------------------------------------*/
    &__scroll{
        position: absolute;
        right: g.get_vw(25);
        bottom: -100px;
        display: flex;
        align-items: center;
        writing-mode: vertical-rl;
        color: g.color('key');
        font-size: 1.4rem;
        gap: 10px;
        
        @include g.mq('md') {
            right: 100px;
            bottom: -130px;
            font-size: 1.5rem;
        }

        &-bar{
            width: 3px;
            height: 107px;
            position: relative;
            overflow: hidden;

            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 1px;
                height: 100%;
                margin: auto;
                background-color: g.color('bg-thick');
            }

            span{
                display: block;
                width: 3px;
                height: 26px;
                background-color: g.color('key');
                position: absolute;
                top: 0;
                animation: scroll-animation 2s linear infinite;
            }

            @keyframes scroll-animation {
                0% { top: -107px; }
                100% { top: 107px; }
            }
        }
    }

    /*------------------------------------------
        背景アニメーション
    ------------------------------------------*/
    &__bg{
        overflow: hidden;

        svg{
            width: 108%;
            height: 100%;

            @include g.mq('sm') {
                width: 190%;
                height: 100%;
                margin-left: -47%;
            }
        }
        
        /*------------------------------------------
            SP設定
        ------------------------------------------*/
        @keyframes dashAnimation_sp01 {
            0% {
                stroke-dashoffset: 9200; //動く量
            }
            14%,30%{
                stroke-dashoffset: 8000; //動く量
            }
            100% {
                stroke-dashoffset: 0;
            }
        }
        @keyframes dashAnimation_sp02 {
            0% {
                stroke-dashoffset: 9200; //動く量
            }
            14%,30%{
                stroke-dashoffset: 8000; //動く量
            }
            100% {
                stroke-dashoffset: 8000;
            }
        }
        
        #myPath1 {
            stroke-dasharray: 1200, 8000; //幅、ギャップ(間隔)
            animation: dashAnimation_sp01 7s linear forwards, dashAnimation_sp02 7s linear 7s forwards;
            stroke: #00A56E; /* 赤色の線 */
        }
        
        /*------------------------------------------
            PC設定
        ------------------------------------------*/
        @keyframes dashAnimation_pc01 {
            0% {
                stroke-dashoffset: 8800; //動く量
            }
            21%,40%{
                stroke-dashoffset: 6970; //動く量
            }
            100% {
                stroke-dashoffset: 0;
            }
        }
        @keyframes dashAnimation_pc02 {
            0% {
                stroke-dashoffset: 8800; //動く量
            }
            21%,40%{
                stroke-dashoffset: 6970; //動く量
            }
            100% {
                stroke-dashoffset: 6970;
            }
        }
        
        #myPath2 {
            stroke-dasharray: 800, 8000; //幅、ギャップ(間隔)
            animation: dashAnimation_pc01 8s linear forwards, dashAnimation_pc02 8s linear 8s forwards;
            stroke: #00A56E; /* 赤色の線 */

        }
    }
}

/*------------------------------------------
    message
------------------------------------------*/
.message{
    margin-bottom: 218px;

    &__inner{
		width: 85%;
		max-width: 100%;
		margin: 0 auto;
		
		@include g.mq('md') {
			width: 100%;
			max-width: 1495px;
			margin: 0 auto;
		}
    }
    
    &__col{
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        gap: 60px;
        
        @include g.mq('md') {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
        }
    }

    &__box-txt{
        position: relative;

        @include g.mq('md') {
            width: 550px;
        }
    }

    &__bg-txt{
        position: absolute;
        bottom: 25px;
        right: 0;
        font-size: 6.0rem;
        writing-mode: vertical-rl;
        font-weight: bold;
        font-family: g.font('tit');
        color: #FFF;
        line-height: 1;

        @include g.mq('md') {
            position: static;
            margin-top: -140px;
            margin-left: 10px;
        }
        
        @include g.mq('lg') {
            font-size: 9.5rem;
        }
    }

    &__note{
        font-size: 1.4rem;
        
        @include g.mq('md') {
            font-size: 1.5rem;
        }
    }

    &__tit{
        font-size: 2.0rem;
        line-height: 1.6;
        margin-bottom: 20px;
        
        @include g.mq('md') {
            font-size: 3.5rem;
        }
    }

    &__txt{
        margin-bottom: 25px;
        font-size: 1.4rem;
        
        @include g.mq('md') {
            font-size: 1.6rem;
            line-height: 2.438;
        }
    }

    &__pic{
        img{
            @include g.mq('sm') {
                transform: scale(1.1);
            }
        }
    }

    &__btn{
        a{
            @include g.mq('sm') {
                justify-content: center;
            }
        }
    }
}

/*------------------------------------------
    about-service
------------------------------------------*/
.about-service{
    position: relative;
    overflow-x: clip;

    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: g.color('key');
        z-index: -1;
        border-radius: 0 25px 25px 0;
        
        @include g.mq('md') {
            width: 50%;
            border-radius: 0;
        }
    }

    &__inner{
        position: relative;
		width: 85%;
		max-width: 100%;
        margin: 0 auto;
		
		@include g.mq('md') {
            width: 100%;
			max-width: 1495px;
            padding-right: g.clamp_size(0,120,768,1370);
		}
    }
    
    &__col{
        background-color: g.color('key');
        border-radius: 0 49px 49px 0;
        padding-bottom: 40px;
        
        @include g.mq('md') {
            display: flex;
            justify-content: space-between;
            padding: 115px 60px 70px 0;
        }
    }

    &__pic{
        text-align: center;
        margin-bottom: 20px;
        
        @include g.mq('md') {
            flex: 0 0 auto;
            margin-left: 30px;
            margin-bottom: 0;
        }
        
        img{
            width: 95%;
            margin-top: calc(-1 * 135px);
            
            @include g.mq('md') {
                margin-top: calc(-1 * (115px + 90px));
                width: g.clamp_size(200,483,768,1370);;
            }
        }
    }

    &__box-txt{
        @include g.mq('md') {
            margin-left: 90px;
            margin-left: g.clamp_size(10,90,768,1370);
        }
    }

    &__bg-txt{
        position: absolute;
        bottom: 87px;
        right: -17px;
        font-size: 5.0rem;
        font-weight: bold;
        font-family: g.font('tit');
        writing-mode: vertical-rl;
        color: transparent;
        -webkit-text-stroke: 1px #FFF;
        line-height: 0.85;
        
        @include g.mq('md') {
            position: static;
            margin-left: 10px;
            font-size: 6.0rem;
            line-height: 1;
        }
        
        @include g.mq('lg') {
            font-size: 9.5rem;
        }
    }

    &__box-txt{
        padding-right: 80px;
        
        @include g.mq('md') {
            padding-right: 0;
        }
    }

    &__tit{
        margin-bottom: 20px;
        font-size: 1.5rem;
        color: #FFF;
        
        @include g.mq('md') {
            font-size: 1.5rem;
        }
    }

    &__txt{
        margin-bottom: 25px;
        font-size: 1.3rem;
        color: #FFF;
        
        @include g.mq('md') {
            font-size: 1.6rem;
            line-height: 2.438;
        }
    }

    &__btn{
        @include g.mq('sm') {
            text-align: center;
            margin-left: 12vw;
        }
    }
}

/*------------------------------------------
    service
------------------------------------------*/
.service{
    margin: 60px 0 g.get_vw(230);

    @include g.mq('md') {
        transform: scale(0.7);
        margin: 50px 0 100px;
    }

    @include g.mq('lg') {
        transform: scale(0.8);
        margin: 100px 0 150px;
    }
    
    @include g.mq('xl') {
        transform: scale(1.0);
        margin: 250px 0;
    }

    &__inner{
		width: 85%;
		max-width: 100%;
		margin: 0 auto;
		
        @include g.mq('md') {
            position: relative;
            width: 896px;
            height: 896px;
        }
    }

    &__box-txt{
        @include g.mq('md') {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 433px;
            margin: 0 auto;
            text-align: center;
            z-index: 2;
        }
    }

    &__txt{
        margin-bottom: 40px;
        font-size: 1.3rem;
        
        @include g.mq('md') {
            margin-bottom: 25px;
            font-size: 1.5rem;
        }
    }

    &__btn{
        .btn{
            justify-content: center;
        }
    }

    &__list{
        @include g.mq('sm') {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-bottom: 20px;
        }
    }

    &__item{
        background-color: #FFF;
        border-radius: 25px;
        padding: 50px 30px 25px;
        text-align: center;
        z-index: 1;
        min-height: 290px;
        font-feature-settings : "palt";
        
        @include g.mq('md') {
            border-radius: 49px;
            position: absolute;
            width: 291px;
            height: 291px;
        }

        &:nth-of-type(1){
            
            @include g.mq('md') {
                top: -60px;
                right: 0;
                left: 0;
                margin: auto;
            }
        }

        &:nth-of-type(2){
            
            @include g.mq('md') {
                top: -133px;
                bottom: 0;
                right: -124px;
                margin: auto;
            }
        }

        &:nth-of-type(3){
            
            @include g.mq('md') {
                bottom: -65px;
                right: 82px;
                margin: auto;
            }
        }

        &:nth-of-type(4){
            
            @include g.mq('md') {
                bottom: -65px;
                left: 82px;
                margin: auto;
            }
        }

        &:nth-of-type(5){
            
            @include g.mq('md') {
                top: -133px;
                bottom: 0;
                left: -124px;
                margin: auto;
            }
        }
    }

    &__item-pic{
        margin-bottom: 5px;
    }

    &__item-tit{
        font-size: 2.4rem;
        margin-bottom: 30px;
    }

    &__item-txt{
        font-size: 1.3rem;
        line-height: 1.915;
    }

    &__bg{
        display: none;
        
        @include g.mq('md') {
            display: block;
        }
        
        img{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            &:nth-of-type(1){
                width: 717px;
                height: auto;
                animation: rotation 20s infinite linear;
            }
            &:nth-of-type(2){
                width: 100%;
                height: auto;
                animation: rotation 40s infinite linear;
            }

            @keyframes rotation {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
        }
    }

    &__btn{
        @include g.mq('sm') {
            text-align: center;
        }
    }
}

/*------------------------------------------
    recruit
------------------------------------------*/
.recruit{
    margin-bottom: 80px;
    position: relative;
    
    @include g.mq('md') {
        margin-bottom: 175px;
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: g.color('key');
        z-index: -1;
        border-radius: 25px 0 0 25px;
        
        @include g.mq('md') {
            width: 50%;
            border-radius: 0;
        }
    }

    &__inner{
        position: relative;
		width: 85%;
		max-width: 100%;
        margin: 0 auto;
		
		@include g.mq('md') {
			width: 100%;
			max-width: 1495px;
            padding-left: g.clamp_size(0,448,768,1370);
		}
    }
    
    &__col{
        padding: g.get_vw(80) 0 50px;
        background-color: g.color('key');
        border-radius: 49px 0 0 49px;
        
        @include g.mq('md') {
            display: flex;
            justify-content: space-between;
            padding: 80px 0 110px 310px;
        }
    }

    &__pic{
        margin-top: calc(-1 * (g.get_vw(243)));
        margin-bottom: 30px;
        
        @include g.mq('md') {
            position: absolute;
            top: -100px;
            left: -716px;
            left: g.clamp_size(-716,-334,768,1370);
            margin-top: 0;
            margin-bottom: 0;
        }

        img{
            @include g.mq('sm') {
                max-width: initial;
                width: 125%;
                margin-left: calc(-1 * (g.get_vw(80)));
                overflow: hidden;
            }
        }
    }

    &__box-txt{
        padding-right: 66px;

        @include g.mq('md') {
            padding-right: 0;
            padding-right: 30px;
            padding-right: g.clamp_size(30,150,768,1370);
        }
    }

    &__bg-txt{
        position: absolute;
        bottom: 100px;
        right: -25px;
        padding-right: 16px;
        font-size: 6.0rem;
        font-weight: bold;
        font-family: g.font('tit');
        writing-mode: vertical-rl;
        line-height: 1;
        color: transparent;
        -webkit-text-stroke: 1px #FFF;
        
        @include g.mq('md') {
            position: static;
            margin-right: 10px;
        }
        
        @include g.mq('lg') {
            font-size: 9.5rem;
        }
    }

    &__tit{
        margin-bottom: 20px;
        font-size: 1.4rem;
        color: #FFF;
        
        @include g.mq('md') {
            font-size: 1.5rem;
        }
    }

    &__txt{
        margin-bottom: 25px;
        font-size: 1.3rem;
        color: #FFF;
        
        @include g.mq('md') {
            font-size: 1.6rem;
            line-height: 2.438;
        }
    }

    &__btn{
        @include g.mq('sm') {
            text-align: center;
        }
        a{
            @include g.mq('sm') {
                justify-content: center;
                margin-left: g.get_vw(45);
            }
        }
    }
}

/*------------------------------------------
    number
------------------------------------------*/
.number{
    $this: '.number';
    position: relative;
    z-index: 2;

    &__box{
        display: flex;
        flex-direction: column;
        gap: 30px;
        background-color: #FFF;
        border-radius: 25px 25px 0 0;
        padding: 45px;
        
        @include g.mq('lg') {
            flex-direction: row;
            justify-content: center;
            padding: 80px 10px 50px;
            border-radius: 49px 49px 0 0;
            gap: 95px;
        }
        
        @include g.mq('xl') {
            gap: 130px;
        }
    }

    &__sub-tit{
        margin-bottom: 35px;
        font-size: 1.6rem;
        color: g.color('key');
        text-align: center;
        
        @include g.mq('md') {
            margin-bottom: 50px;
            font-size: 1.9rem;
        }
    }

    &__item{
        min-width: 223px;
        padding-bottom: 3px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        border-bottom: 1px solid g.color('border-thick');
        
        @include g.mq('md') {
            font-size: 1.9rem;
        }
    }

    &__btn{
        background-color: g.color('key');
        text-align: center;
        border-radius: 0 0 25px 25px;
        
        @include g.mq('md') {
            border-radius: 0 0 49px 49px;
        }

        a{
            display: flex;
            gap: 10px;
            padding: 15px 10px;
            justify-content: center;
            align-items: center;

            &::before{
                position: relative;
                margin: 0;
            }
        }
    }
}

/*------------------------------------------
    entry
------------------------------------------*/

}

