/*------------------------------------------
inner
------------------------------------------*/
@use "../global" as g;

.inner {
	width: g.$inner-width-sp;
	max-width: 100%;
	margin: 0 auto;
    
    @include g.mq('md') {
		width: g.$inner-width;
        padding: 0 20px;
		margin: 0 auto;
	}
	
	&.is-pc {
        @include g.mq('sm') {
            width: 100%;     
        }
	}

    &.is-sp {
        @include g.mq('md') {
            width: 100%;
        }
    }

    &.is-small {
        @include g.mq('lg') {
            width: g.$inner-width-small;
        }
    }
}