@use "../global" as g;

/*------------------------------------------
    lower-tit
------------------------------------------*/
.lower-tit {

	&::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 280px;
		background-color: #FFF;
		border-radius: 0 0 0 280px;
		
		@include g.mq('md') {
			height: 385px;
			border-radius: 0 0 0 400px;
		}

		@include g.mq('lg') {
			height: 467px;
		}
	}

	&.is-bg-green{
		&::after{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 280px;
			background-color: g.color('key-thick');
			z-index: -1;
			
			@include g.mq('md') {
				height: 385px;
			}
	
			@include g.mq('lg') {
				height: 467px;
			}
		}
	}

	.inner{
		position: relative;
	}
	
	&__box{
		padding-top: 15px;
		margin-bottom: 89px;
		
		@include g.mq('md') {
			padding-top: 50px;
			margin-bottom: 99px;
		}
		
		@include g.mq('lg') {
			margin-bottom: 91px;
		}
	}

	&__tit{
		width: 205px;
		margin-left: auto;
		margin-right: 0;
        font-size: 4.8rem;
		font-family: g.font('tit');
		line-height: 1.3;
		letter-spacing: 0.04em;
		
		@include g.mq('md') {
			width: 395px;
			margin-right: 20px;
			font-size: 9.0rem;
		}
		
		span{
			display: block;
			font-size: 1.8rem;
			font-family: g.font('base');
			font-weight: normal;
			
			@include g.mq('md') {
				font-size: 2.2rem;
			}
		}
	}
}