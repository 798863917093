@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	ボタン
------------------------------------------*/
.btn {
	display: inline-block;
	position: relative;
	color: g.color('key');
	font-size: 1.2rem;
	font-weight: bold;
	padding: 12px 0 12px 47px;
	
	@include g.mq('md') {
		padding: 15px 0 15px 62px;
		font-size: 1.5rem;
	}

	&::before{
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 37px;
		height: 37px;
		margin: auto;
		
		@include g.mq('md') {
			left: 0;
			width: 52px;
			height: 52px;
		}
	}
	
	&.is-green{
		&::before{
			background: url(../img/common/arrow_g.svg) no-repeat center / contain;
		}
	}

	&.is-white{
		color: #FFF;
		
		&::before{
			background: url(../img/common/arrow_w.svg) no-repeat center / contain;
		}
	}
}