/*******************************************
    recruit
*******************************************/
@use "../global" as g;

#recruit {

    /*------------------------------------------
        トップページ
    ------------------------------------------*/
    &.index {

        .sec {
            padding-bottom: 60px;
            
            @include g.mq('md') {
                padding-bottom: 150px;
            }
        }

        .top-tit {
            font-size: 3.4rem;
            letter-spacing: .02em;
            margin-top: 60px;
            line-height: 1;

            @include g.mq('md') {
                font-size: 6.7rem;
                margin-top: 0;
            };
        }

        .top-tit-sub {
            font-size: 1.4rem;
            letter-spacing: .02em;
            margin-top: 12px;
            line-height: 1;

            @include g.mq('md') {
                font-size: 1.8rem;
            }
        }

        .top-txt {
            line-height: 2.125;
            margin-top: 20px;
        }

        .top-btn {
            margin-top: 12px;

            @include g.mq('md') {
                margin-top: 17px;
            }
        }

        .top-img {
            display: block;
            margin-top: 24px;
            width: 100%;

            @include g.mq('md') {
                margin-top: 0;
            }
        }

        //横並びコンテンツ
        .cont-flex {
            $this: '.cont-flex';

            @include g.mq('md') {
                margin-top: 165px;
                display: flex;
                flex-wrap: wrap;
                gap: 124px;
            }

            &__item {
                @include g.mq('md') {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 6.05%;
                }

                &:nth-child(2n-1) {
                    @include g.mq('md') {
                        flex-direction: row-reverse;
                    }

                    #{$this}__box {
                        @include g.mq('md') {
                            padding-left: 0;
                            padding-right: g.clamp_size(0,76,1340,1440);
                        }
                    }
                }
            }

            &__box {
                @include g.mq('md') {
                    flex: 1;
                    padding-left: g.clamp_size(0,100,1340,1440);
                }
            }

            &__txt {
                @include g.mq('md') {
                    margin-top: 34px;
                }
            }

            &__img {
                @include g.mq('md') {
                    width: 47.92%;
                }
            }
        }

        //メンバー紹介
        .our-members {

            @include g.mq('md') {
                margin-top: 147px;
            }

            &__inner {
                @include g.mq('md') {
                    width: 1525px;
                }
            }

            &__box {
                @include g.mq('md') {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    max-width: 507px;
                    margin: 0 auto;
                }
            }

            &__img-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @include g.mq('md') {
                    flex-wrap: nowrap;
                    margin-top: -104px;
                }
            }

            &__item {
                width: 48%;

                @include g.mq('md') {
                    margin-top: 56px;
                    width: 21.114%;
                }

                /* &:nth-child(2n) {
                    margin-top: -30px;
                }

                &:nth-child(1) {
                    @include g.mq('md') {
                        margin-top: 52px;
                    }
                }

                &:nth-child(2) {
                    @include g.mq('md') {
                        margin-top: 31px;
                    }
                }

                &:nth-child(3) {
                    @include g.mq('md') {
                        margin-top: 56px;
                    }
                } */
            }

        }

        //縦並びコンテンツ
        .cont-row {

            @include g.mq('md') {
                display: flex;
                justify-content: space-between;
                margin: 166px auto 0;
                max-width: 1200px;
            }

            &__item {
                @include g.mq('md') {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    width: 47%;
                    gap: 35px;
                }
            }

            &__box {
                @include g.mq('md') {
                    padding: 0 39px;
                }
            }

            &__tit-area {
                @include g.mq('md') {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    gap: 12px;
                }
            }

            &__tit {
                @include g.mq('md') {
                    font-size: 5.0rem;
                }
            }

            &__tit-sub {
                @include g.mq('md') {
                    margin-top: 0;
                    margin-bottom: 8px;
                }
            }

            &__txt {
                @include g.mq('md') {
                    margin-top: 12px;
                }
            }

        }

    }

    /*------------------------------------------
        下層ページ：our-members
    ------------------------------------------*/
    &.our-members{

        //リスト
        .members-list {
            $this: ".members-list";

            width: 85%;
            display: flex;
            flex-wrap: wrap;
            gap: 60px;
            margin: 60px auto;

            @include g.mq('md') {
                width: min(1490px, 100%);
                padding: 0 40px;
                gap: 175px;
                margin: 165px auto;
            }

            &__item {
                display: flex;
                flex-wrap: wrap;
                gap: 32px;

                @include g.mq('md') {
                    gap: g.clamp_size(30,120,768,1490);
                    align-items: flex-end;
                    justify-content: center;
                }

                &:nth-of-type(2n) {
                    @include g.mq('md') {
                        flex-direction: row-reverse;
                    }

                    #{$this}__txt-area {
                        @include g.mq('md') {
                            padding-bottom: g.clamp_size(0,56,1140,1490);
                        }
                    }
                }
            }

            &__txt-area {
                @include g.mq('md') {
                    padding-bottom: g.clamp_size(0,47,1140,1490);
                    width: g.clamp_size(410,478,768,1490);
                }
            }

            &__name {
                color: g.color('key');
                font-size: 3.4rem;
                line-height: 1.2;
                font-weight: bold;
                margin-bottom: .7rem;

                @include g.mq('md') {
                    font-size: 6.7rem;
                }
            }

            &__work {
                color: g.color('key');
                margin-bottom: 1.4rem;
            }

            &__txt {
                line-height: 2.3125;
            }

            &__btn {
                margin-top: 12px;

                @include g.mq('md') {
                    margin-top: 25px;
                }
            }

            &__img {
                img {
                    border-radius: 20px;
                }

                @include g.mq('md') {
                    flex: 1;
                }
            }
        }

    }

    /*------------------------------------------
        下層ページ：各メンバーページ
    ------------------------------------------*/
    &.bottom-layer{

        .sec {
            overflow-x: hidden;

            &__tit {
                margin-bottom: 24px;

                @include g.mq('md') {
                    margin-bottom: 60px;
                }
            }
        }

        // MV 
        .interview-mv {
            position: relative;
            margin-top: calc(g.clamp_size(0,107,768,1440) * -1);
            margin-bottom: 100px;
            width: 100%;
            overflow: hidden;

            @include g.mq('md') {
                margin-bottom: 140px;
            }

            &__bg {
                position: absolute;
                z-index: -1;
                bottom: 110px;
                right: -20px;
                font-size: 16rem;
                line-height: 0.8;
                letter-spacing: .01em;
                color: #fff;

                @include g.mq('md') {
                    bottom: auto;
                    top: g.clamp_size(40,192,768,1440);
                    right: -40px;
                    font-size: 27.8rem;
                }
            }

            &__wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                
                @include g.mq('md') {
                    gap: g.clamp_size(34,175,1000,1440);
                    align-items: flex-end;
                }
            }

            &__pic {
                width: 100%;
                aspect-ratio: 833/553;

                @include g.mq('md') {
                    flex: 1;
                    max-width: 833px;
                }

                iframe {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }

            &__inner {
                @include g.mq('md') {
                    margin-bottom: 20px;
                }
            }

            &__name {
                font-size: 2.4rem;
                letter-spacing: .04em;

                @include g.mq('md') {
                    font-size: 3.8rem;
                }
            }

            &__work {
                line-height: 1.88;
                font-size: 1.4rem;
                letter-spacing: .18em;
                color: g.color('key');
                margin-top: 5px;

                @include g.mq('md') {
                    font-size: 1.8rem;
                }
            }

            &__former {
                display: inline-block;
                line-height: 1.88;
                font-size: 1.4rem;
                letter-spacing: .18em;
                color: #fff;;
                background-color: g.color('key');
                padding: 3px 13px 3px 14px;
                margin-top: 10px;

                @include g.mq('md') {
                    font-size: 1.8rem;
                    padding: 10px 24px 10px 30px;
                    margin-top: 18px;
                }
            }
        }
        
        //インタビューコンテンツ
        .interview {
            $this: ".interview";
            padding-bottom: 60px;
            width: 100%;
            
            @include g.mq('md') {
                margin-top: -43px;
                padding-bottom: 235px;
            }

            &.is-bg {
                background: g.color('key-thick');
                margin-top: 0;
                padding: 60px 0;
                color: #e8e8e8;

                @include g.mq('md') {
                    padding: 140px 0 150px;
                }
            }

            &__wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 60px;
                
                @include g.mq('md') {
                    justify-content: space-between;
                    gap: g.clamp_size(40,150,768,1440);
                }
            }

            &__img {
                width: 100%;

                @include g.mq('md') {
                    flex: 1;
                }

                img {
                    border-radius: 20px;
                }
            }

            &__box {
                display: flex;
                flex-direction: column;
                gap: 60px;
                
                @include g.mq('md') {
                    gap: 155px;
                    flex: 1;
                    max-width: 600px;
                }

                &.is-img {
                    @include g.mq('md') {
                        padding-top: 115px;
                        max-width: 656px;
                    }
                }

                &.is-bg01 {
                    @include g.mq('md') {
                        gap: 120px;
                    }
                }

                &.is-bg02 {
                    @include g.mq('md') {
                        padding-top: 70px;
                        gap: 125px;
                    }
                }
            }

            &__item {
                position: relative;
                z-index: 1;

                &:nth-of-type(2n) {
                    #{$this}__num {
                        color: g.color('key-thin');
                        opacity: .5;
                    }
                }
            }

            &__num {
                position: absolute;
                z-index: -1;
                font-family: g.font('tit');
                font-size: 12rem;
                font-weight: bold;
                letter-spacing: -.04em;
                line-height: .8;
                color: g.color('key');
                opacity: .3;
                top: 0;
                right: -1%;

                @include g.mq('md') {
                    font-size: 21rem;
                    top: auto;
                    right: auto;
                }

                &.is-01 {
                    @include g.mq('md') {
                        top: 0;
                        right: calc(g.clamp_size(0,60,1400,1642) * -1);
                    }
                }

                &.is-02 {
                    @include g.mq('md') {
                        bottom: -75px;
                        left: calc(g.clamp_size(40,160,1400,1642) * -1);
                    }
                }

                &.is-03 {
                    @include g.mq('md') {
                        top: -16px;
                        right: calc(g.clamp_size(40,103,1400,1642) * -1);
                    }
                }

                &.is-04 {
                    @include g.mq('md') {
                        bottom: -161px;
                        left: calc(g.clamp_size(20,99,1400,1642) * -1);
                    }
                }

                &.is-05 {
                    @include g.mq('md') {
                        top: -13px;
                        right: calc(g.clamp_size(20,120,1400,1642) * -1);
                    }
                }
            }

            &__tit {
                position: relative;
                display: block;
                font-size: 1.8rem;
                letter-spacing: .18em;
                line-height: 1.7727;
                padding-bottom: 35px;

                @include g.mq('md') {
                    font-size: 2.2rem;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 20px;
                    width: 20px;
                    height: 2px;
                    background: g.color('key');
                }
            }

            &__txt {
                letter-spacing: .01em;
                line-height: 2.3125;
            }


        }

        //スケジュール
        .schedule {
            margin:40px auto 30px;
            padding: 40px 7.5% 30px;
            background: #fff;
            border-radius: 25px;

            @include g.mq('md') {
                border-radius: 39.5px;
                margin: g.clamp_size(70,142,768,1400) auto 77px;
                padding: 86px g.clamp_size(34,100,768,1400);
            }

            &__tit {
                margin-bottom: 24px;

                @include g.mq('md') {
                    margin-bottom: 70px;
                }
            }

            &__wrap {
                display: flex;
                flex-wrap: wrap;
            }

            &__img {
                width: 100%;

                @include g.mq('md') {
                    padding: 0 g.clamp_size(0,36.5,1260,1440);
                }

            }
        }

        //メッセージ
        .message {
            margin-bottom: 40px;
            padding: 40px 7.5%;
            background: #fff;
            border-radius: 25px;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            @include g.mq('md') {
                border-radius: 39.5px;
                margin: 0 auto 130px;
                padding: 86px g.clamp_size(34,100,768,1400) 84px;
                gap: g.clamp_size(20,160,768,1440);
            }

            &__tit {
                margin-bottom: 0;

                @include g.mq('md') {
                    width: 420px;
                }
            }

            &__txt {
                color: g.color('key');
                font-size: 1.4rem;
                padding-top: 6px;

                @include g.mq('md') {
                    flex: 1;
                    font-size: 2.0rem;
                    min-width: 360px;
                    line-height: 2.15;
                    letter-spacing: .1em;
                }
            }
        }
    }

    /*------------------------------------------
        下層ページ：募集要項
    ------------------------------------------*/

    &.job-description {
        background: #fff;

        @include g.mq('md') {
            background: g.color('bg');
        }

        .job-wrap {
            margin-top: 40px;

            @include g.mq('md') {
                position: relative;
                z-index: 1;
                margin: -54px auto 40px;
                background: #fff;
                border-radius: 39.5px;
                padding: 102px g.clamp_size(20,98,768,1440) 113px;
            }

            &__inner {
                width: 100%;

                @include g.mq('md') {
                    width: 1440px;
                }
            }
        }

        //募集種別
        .job-type {

            &__inner {
                width: 85%;
                margin: 0 auto;

                @include g.mq('md') {
                    width: 100%;
                }
            }

            &__box {

                @include g.mq('md') {
                    display: flex;
                    align-items: stretch;
                    justify-content: flex-start;
                    gap: g.clamp_size(20,72,768,1440);
                    border-bottom: 1px solid g.color('border-table');
                }
            }

            &__tit {
                font-size: 1.5rem;
                font-weight: 500;
                letter-spacing: .28em;
                background: g.color('bg');
                text-align: center;
                padding: 10px 1em;
                display: block;
                width: 100%;

                @include g.mq('md') {
                    font-size: 1.9rem;
                    width: g.clamp_size(140,274,768,1440);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &__txt {
                padding: 14px 2% 0;
                letter-spacing: .12em;

                @include g.mq('md') {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 20px 0;
                }
            }

            &__item {
                position: relative;
                padding-left: 1.12em;
                margin: 8px 0;

                @include g.mq('md') {
                    margin: 0;
                    padding-right: 80px;
                    min-width: 250px;
                    line-height: 2.75;
                }

                &::before {
                    content: "・";
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: g.color('key');
                }
            }
        }

        //募集要項
        .job-description {
            $this: '.job-description';
            background: g.color('bg');
            margin-top: 40px;
            padding: 40px 0 60px;

            @include g.mq('md') {
                margin-top: 60px;
                padding: 0;
                background: none;
            }

            &__inner {
                width: 85%;
                margin: 0 auto;
                
                @include g.mq('md') {
                    width: 100%;
                }
            }

            dl {
                margin-bottom: 2em;

                @include g.mq('md') {
                    margin: 0;
                    display: flex;
                    align-items: stretch;
                }
            }

            dt {
                background: g.color('key');
                padding: .5em 1em;
                text-align: center;
                font-weight: 500;
                color: #fff;

                @include g.mq('md') {
                    width: g.clamp_size(140,274,768,1440);
                    border-bottom: 1px solid #fff;
                    padding: 20px 10px;
                }
            }

            dd {
                padding: 10px 6%;
                line-height: 2.75;
                background: #fff;

                @include g.mq('md') {
                    flex: 1;
                    border-bottom: 1px solid g.color('border-table');
                    padding: 20px 0 20px g.clamp_size(20,72,768,1440);
                }

                span {
                    color: g.color('key');

                    &#{$this}__attention {
                        color: g.color('notice');
                    }
                }
            }

            &__btn {
                display: block;
                font-weight: 500;
                text-align: center;
                letter-spacing: .3em;
                line-height: 70px;
                height: 70px;
                width: 100%;
                max-width: 462px;
                margin: 40px auto 0;
                box-sizing: border-box;
                background: g.color('key-thick');
                color: #fff;
                border-radius: 35px;

                @include g.mq('md') {
                    height: 91px;
                    line-height: 91px;
                    border-radius: 45.5px;
                    margin-top: 63px;
                }
            }
        }
    }

    /*------------------------------------------
        下層ページ：フォロー体制
    ------------------------------------------*/
    &.follow-system {

        .page-nav {
            margin-top: -20px;
            position: relative;
            z-index: 1;

            @include g.mq('md') {
                margin-top: -40px;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                font-size: 1.4rem;
                font-weight: 500;
                letter-spacing: .2em;
                counter-reset: number 0;

                @include g.mq('md') {
                    gap: 25px;
                    font-size: 1.8rem;
                    justify-content: center;
                }
            }

            &__item {
                width: 100%;

                @include g.mq('md') {
                    width: 330px;
                }

                a {
                    display: flex;
                    align-items: center;
                    background: #fff;
                    border-radius: 12px;
                    padding: 10px 20px;

                    @include g.mq('md') {
                        border-radius: 26px;
                        padding: 25px 27px 30px 25px;
                    }
                }

                p {
                    display: block;
                    flex: 1;

                    &::before {
                        counter-increment: number 1;
                        content: "0" counter(number);
                        font-size: 1.4rem;
                        font-weight: 500;
                        letter-spacing: .18em;
                        color: g.color('key');
                        padding-right: 12px;

                        @include g.mq('md') {
                            font-size: 1.8rem;
                            padding-right: 9px;
                        }
                    }
                }

                span {
                    @include g.arrow("bottom", .6rem, 2px, g.color('key'), true);

                    @include g.mq('md') {
                        @include g.arrow("bottom", 1.0rem, 3px, g.color('key'), true);
                    }
                }
            }
        }

    }

    /*------------------------------------------
        下層ページ：福利厚生
    ------------------------------------------*/
    &.benefits {

        .sec {
            margin: 40px 0 60px;

            @include g.mq('md') {
                margin: -40px 0 110px;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 24px;

                @include g.mq('md') {
                    gap: 45px g.clamp_size(20,58,768,1400);
                    align-items: stretch;
                }

                &::after {
                    @include g.mq('md') {
                        content: "";
                        display: block;
                        width: 428px;
                        height: 0;
                    }
                }
            }

            &__item {
                background: #fff;
                border-radius: 25px;
                width: 100%;

                @include g.mq('md') {
                    flex: 1;
                    border-radius: 32px;
                    min-width: 350px;
                    min-height: 465px;
                }
            }

            &__tit {
                font-size: 1.8rem;
                font-weight: 500;
                letter-spacing: .2em;
                text-align: center;
                color: g.color('key-thick');
                padding: 30px 7.5% 24px;
                border-bottom: 1px solid g.color('border-tit');

                @include g.mq('md') {
                    font-size: 2.3rem;
                    padding: 31px 34px;
                }
            }

            &__txt {
                font-size: 1.4rem;
                padding: 24px 7.5% 40px;
                letter-spacing: 0.12em;
                line-height: 2.05;

                @include g.mq('md') {
                    font-size: 1.8rem;
                    padding: 32px g.clamp_size(34,50,768,1400) 40px;
                }
            }
        }

        .example {
            margin-top: 14px;
            letter-spacing: .02em;

            @include g.mq('md') {
                margin-top: 20px;
            }

            &__tit {
                font-size: 1.4rem;
                color: g.color('key');

                @include g.mq('md') {
                    font-size: 1.8rem;
                }
            }

            &__item {
                font-size: 1.2rem;
                line-height: 1.6;
                margin-top: 2px;
                display: block;

                @include g.mq('md') {
                    font-size: 1.4rem;
                    margin-top: 11px;
                }

                &::before {
                    content: "◎";
                }

                span {
                    display: block;

                    @include g.mq('md') {
                        display: inline-block;
                        padding-left: 1em;
                    }
                }
            }
        }

    }

    /*------------------------------------------
        下層ページ：キャリアアップ
    ------------------------------------------*/

    &.career-up {

        .case {
            margin: 60px auto;

            @include g.mq('md') {
                margin: 180px auto 140px;
            }

            &__inner {
                @include g.mq('sm') {
                    width: 100%;
                }
            }

            &__head-tit{
                margin-bottom: 40px;
                
                @include g.mq('md') {
                    margin-bottom: 80px;
                }
            }

            &__item {
                margin-bottom: 50px;

                @include g.mq('md') {
                    margin-bottom: 160px;
                }
            }

            &__tit-area {
                width: 85%;
                margin: 0 auto;

                @include g.mq('md') {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    gap: 24px;
                    margin-bottom: 30px;
                    width: 100%;
                    gap: 20px;
                }
            }

            &__tit {
                display: inline-block;
                font-size: 1.4rem;
                letter-spacing: .1em;
                font-weight: 500;
                color: g.color('key');
                padding: 0 14px;
                margin-bottom: 8px;
                border: 2px solid g.color('key');
                border-radius: 10px;
        
                @include g.mq('md') {
                    display: flex;
                    align-items: center;
                    font-size: 1.8rem;
                    margin-bottom: 0;
                }
            }
    
            &__sub-tit {
                display: block;
                font-size: 2.4rem;
                letter-spacing: .02em;
                line-height: 1.5;
                font-weight: 200;
        
                @include g.mq('md') {
                    font-size: 4.2rem;
                    line-height: 1.2;
                    margin-bottom: 2px;
                }
            }

            &__scroll {
                @include g.mq('sm') {
                    width: 100%;
                    padding-left: 7.5%;
                    padding-bottom: 20px;
                    overflow: scroll;
                }
            }

            &__pic {
                margin-top: 20px;
                width: 950px;

    
                @include g.mq('md') {
                    width: 100%;
                    margin-top: 40px;
                }

                img {
                    width: 100%;
                    max-width: none;

                }
            }
        }
    }

    /*------------------------------------------
        下層ページ：数字で見る
    ------------------------------------------*/

    &.our-numbers {

        .sec {
            margin: 40px 0 60px;

            @include g.mq('md') {
                margin: -40px 0 110px;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 24px;

                @include g.mq('md') {
                    gap: 45px g.clamp_size(20,50,768,1400);
                    align-items: stretch;
                }

                &::after {
                    @include g.mq('md') {
                        content: "";
                        display: block;
                        width: 428px;
                        height: 0;
                    }
                }
            }

            &__item {
                background: #fff;
                border-radius: 25px;
                width: 100%;

                @include g.mq('md') {
                    flex: 1;
                    border-radius: 32px;
                    min-width: 350px;
                }

                &.is-row2 {
                    @include g.mq('md') {
                        flex: 2;
                        min-width: max(768px, 65.286%);
                    }
                }
            }

            &__tit {
                font-size: 1.8rem;
                font-weight: 500;
                letter-spacing: .2em;
                text-align: center;
                color: g.color('key-thick');
                padding: 30px 7.5% 24px;
                border-bottom: 1px solid g.color('border-tit');

                @include g.mq('md') {
                    font-size: 2.3rem;
                    padding: 31px 34px;
                }
            }

            &__cont {
                padding: 24px 7.5% 40px;

                @include g.mq('md') {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 32px g.clamp_size(34,30,768,1400) 40px;
                    min-height: 321px;
                }
            }

            &__img {
                max-width: 220px;
                margin: 0 auto;

                @include g.mq('md') {
                    max-width: 283px;
                }
            }
        }

        // 資格保留率、前職の職種
        .count {
            $this: ".count";
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            max-width: 234px;
            margin: 0 auto;

            @include g.mq('md') {
                justify-content: center;
                max-width: 350px;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 13px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            &__item {
                display: flex;
                align-items: center;
                font-size: 1.5rem;

                @include g.mq('md') {
                    font-size: 1.9rem;
                    letter-spacing: .04em;
                }

                span {
                    font-size: 1.85rem;
                    color: g.color('key');

                    @include g.mq('md') {
                        font-size: 2.7rem;
                    }
                }
            }

            &.is-work {
                max-width: 380px;

                @include g.mq('md') {
                    max-width: 800px;
                }

                #{$this}__list {
                    gap: 4px;
                }
            }
        }

        // 平均残業時間
        .time {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            gap: 23px;
            margin: 0 auto;

            @include g.mq('md') {
                max-width: 330px;
            }

            &__number {
                font-size: 9.0rem;
                letter-spacing: -.08em;
                line-height: 1;
                color: g.color('key');

                @include g.mq('md') {
                    font-size: 13.0rem;
                }
            }

            &__txt {
                display: flex;
                flex-wrap: wrap;
                font-size: 3rem;
                letter-spacing: .16em;
                line-height: 1;
                color: g.color('key-thick');

                @include g.mq('md') {
                    font-size: g.clamp_size(29,44,1439,1440);
                }
            }

            &__year {
                font-size: 1.4rem;
                font-weight: 500;
                letter-spacing: .15em;
                line-height: 1;
                color: g.color('key-thick');
                margin: 8px 0 4px -7px;
                text-align: left;

                @include g.mq('md') {
                    font-size: 1.8rem;
                    margin: 13px 0 6px -7px;
                }
            }
        }

        // 売上高&昨対比
        .sales {
            $this: ".sales";
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: center;
            gap: 40px;
            
            @include g.mq('md') {
                justify-content: space-between;
                padding: 0 6px;
            }

            &__num {
                flex: 1;
                display: flex;
                flex-direction: column-reverse;
                flex-wrap: wrap;
                gap: 26px;
                min-width: 210px;
                max-width: 210px;

                @include g.mq('md') {
                    gap: 13px;
                    max-width: none;
                }
            }

            &__item {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 16px;

                @include g.mq('md') {
                    flex-direction: row;
                    align-items: center;
                    gap: g.clamp_size(26,35,768,1400);
                }

                &.is-konki {

                    #{$this}__period {
                        color: g.color('key-thin');
                        background: g.color('key');
    
                        span {
                            color: #fff;
                        }
                    }

                    #{$this}__yen {
                        font-weight: bold;
                        color: #000;
                    }

                    #{$this}__up {
                        @include g.mq('md') {
                            position: relative;
                            top: -4px;
                        }
                    }
                }

                &.is-zenki {
                    #{$this}__period {
                        background: #333;
                    }
                }
                
            }

            &__period {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 105px;
                font-size: 1.8rem;
                letter-spacing: .18em;
                text-align: center;
                color: #fff;
                background: #8f8f8f;
                padding: 0 3px;
                text-wrap: nowrap;

                @include g.mq('md') {
                    width: 136px;
                    font-size: 2.4rem;
                    padding: 3px;
                }

                span {
                    font-size: 1.3rem;
                    letter-spacing: .24em;

                    @include g.mq('md') {
                        font-size: 1.6rem;
                    }
                }
            }

            &__txt {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: center;
                gap: 11px;

                @include g.mq('md') {
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0;
                }
            }

            &__yen {
                font-size: 3.2rem;
                letter-spacing: .14em;
                color: #666;
                line-height: 1;
                text-align: center;
                text-wrap: nowrap;

                @include g.mq('md') {
                    font-size: 3.65rem;
                    width: 230px;
                    text-align: left;
                }

                span {
                    font-size: 1.5rem;

                    @include g.mq('md') {
                        font-size: 1.9rem;
                        padding-bottom: 4px;
                    }
                }
            }

            &__up {
                color: g.color('key');
                letter-spacing: .14em;
                line-height: 1.5;
                text-align: right;

                @include g.mq('md') {
                    text-align: left;
                    margin-left: -4px;
                    padding-top: 7px;
                }

                span {
                    color: #333;
                    display: block;
                    font-size: 1.2rem;
                    letter-spacing: .28em;
                    padding-right: 9px;

                    @include g.mq('md') {
                        padding-right: 0;
                        margin-bottom: 1px;
                        margin-left: 9px;
                        font-size: 1.45rem;
                    }
                }
            }

            &__img {
                width: 164px;

                @include g.mq('md') {
                    margin-bottom: 10px;
                    max-width: none;
                    width: 164px;
                }
            }


        }

    }

}

