@use "../global" as g;

/*------------------------------------------
    lower-mv
------------------------------------------*/
.lower-mv {
	$this: ".lower-mv";
	background-color: g.color('key-thick');

	&__inner{
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		
		@include g.mq('md') {
			width: 1920px;
			display: flex;
			justify-content: flex-end;
			gap: g.clamp_size(80,150,1400,1920);
		}
	}
	
	&__box{
		padding-bottom: 30px;

		@include g.mq('sm') {
			width: 85%;
			max-width: 100%;
			margin: 0 auto;
		}
		
		@include g.mq('md') {
			padding-bottom: 60px;
			padding-left: g.clamp_size(20,250,1400,1920);
			position: relative;
		}
	}

	&__pic{

		@include g.mq('md') {
			flex: 0 0 auto;
			width: g.clamp_size(280,644,768,1920);
		}

		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__tit{
		padding-top: 40px;
		margin-bottom: 5px;
        font-size: 3.4rem;
		font-family: g.font('tit');
		line-height: 1.3;
		letter-spacing: 0.04em;
		color: #FFF;
		
		@include g.mq('md') {
			padding-top: 80px;
			margin-bottom: 30px;
			font-size: 6.7rem;
		}
	}

	&__unit{
		@include g.mq('lg') {
			display: flex;
			gap: 70px;
		}
	}

	&__name{
		margin-bottom: 20px;
		font-size: 1.8rem;
		color: g.color('key-thin');
		white-space: nowrap;
		
		@include g.mq('md') {
			margin-bottom: 0;
			font-size: 2.2rem;
		}
	}

	&__catchcopy{
		margin-bottom: 20px;
		font-size: 1.8rem;
		letter-spacing: 0.12em;
		color: #FFF;
		
		@include g.mq('md') {
			font-size: 3.2rem;
		}

		span{
			color: g.color('key-thin');
		}
	}

	&__txt{
		font-size: 1.4rem;
		line-height: 2.222;
		color: #FFF;
		
		@include g.mq('md') {
			font-size: 1.8rem;
		}
		
		&.is-big{
			font-size: 1.8rem;

			@include g.mq('md') {
				font-size: 2.1rem;
			}
		}
	}

	&__note{
		position: relative;
		margin-top: 20px;
		padding-top: 20px;
		color: #FFF;
		line-height: 2.313;

		&::before{
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 23px;
			height: 1px;
			background-color: g.color('key-thin');
		}
	}
	
    /*------------------------------------------
        画像がない場合は背景と同じブロックを設置する
    ------------------------------------------*/
	&.is-img-none {
		#{$this}__inner{
			@include g.mq('md') {
				justify-content: space-between;
			}
		}

		#{$this}__tit{
			@include g.mq('md') {
				margin-bottom: 0;
			}
		}
	
		#{$this}__box{
			@include g.mq('md') {
				padding-bottom: 100px;
			}
		}
	}

	&__dummy-pic{
		display: none;
		
		@include g.mq('md') {
			flex: 0 0 auto;
			display: block;
			width: g.clamp_size(280,644,768,1920);
			height: auto;
			background-color: g.color('bg');
		}
	}
	
    /*------------------------------------------
        画像が追従するパターン
    ------------------------------------------*/
	&.is-sticky{
		#{$this}__pic{
			img{
				@include g.mq('md') {
					position: sticky;
					top: 60px;
					overflow-y: auto;
					width: 100%;
					height: auto;
				}
				
				@include g.mq('lg') {
					top: 100px;
				}
			}
		}
	}
}