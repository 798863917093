@use "../global" as g;

/*------------------------------------------
	header
------------------------------------------*/
.header {
	$this: '.header';

	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	min-width: 100%;
	z-index: map-get(g.$z-index, 'header');

	#header &{
		background-color: g.color('bg');
	}

	&__inner{
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		
		@include g.mq('lg') {
			width: 100%;
			max-width: 1620px;
			margin: 0 auto;
		}
	}
	
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: map-get(g.$headerH, 'sm');

		@include g.mq('lg') {
			height: map-get(g.$headerH, 'md');
		}
	}

	&__logo {
		width: 145px;
		margin-left: 30px;

		@include g.mq('lg') {
			width: 219px;
		}
	}

	/*------------------------------------------
		一定位置までスクロールしたら出現
	------------------------------------------*/
	&.is-fixed{
		position: fixed;
		top: calc(-1* map-get(g.$headerH, 'sm'));
		background-color: #FFF;
		
		@include g.mq('lg') {
			top: calc(-1* map-get(g.$headerH, 'md'));
		}
		
		#{$this}__flex{
			height: 60px;
			
			@include g.mq('lg') {
				height: 100px;
			}
		}
		
		#frontpage &{
			background-color: g.color('bg');
		}
	}

	&.is-transition{
		transition: .4s;
	}

	&.is-scroll{
		top: 0;
	}
}