@use "../global" as g;

/*------------------------------------------
	グロナビ
------------------------------------------*/
.gnav {
	z-index: map-get(g.$z-index, 'header');

	@include g.mq('lg-max') {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-top: 60px;
		background-color: g.color('bg');
		visibility: hidden;
		opacity: 0;
		transform: translateX(-100%);
		transition: 0.4s;
	
		&.is-active {
			visibility: visible;
			opacity: 1;
			transform: translateX(0);
			overflow: scroll;
		}
	}

	&__close {
		@include g.close-icon(30px, 2px, g.color('base'));
		position: absolute;
		top: 23px;
		right: g.get-inner-side(g.$inner-width-sp);

		@include g.mq('lg') {
			display: none;
		}
	}

	&__list {
		padding: 30px;

		@include g.mq('lg') {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 30px;
			width: auto;
		}
	}

	&__item {
		position: relative;

		@include g.mq('lg-max') {
			border-top: 1px solid g.color('border');
		}

		a {
			display: block;
			font-size: 1.5rem;
			letter-spacing: 0.2em;
			font-family: g.font('tit');

			@include g.mq('lg-max') {
				padding: 20px 0;
			}

			@include g.mq('lg') {
				font-size: 1.3rem;
			}
		}

		&.is-active{
			> a{
				position: relative;

				&::before{
					@include g.mq('lg') {
						content: "";
						position: absolute;
						bottom: -10px;
						right: 0;
						left: 0;
						width: 5px;
						height: 5px;
						margin: auto;
						background: g.color('key');
						border-radius: 10px;
					}
				}
			}
		}

		&.is-contact{
			@include g.mq('lg-max') {
				padding-top: 30px;
				
				a{
					text-align: center;
				}
			}

			a{
				padding: 15px 30px;
				color: g.color('key');
				background: #FFF;
				border-radius: 30px;
				box-shadow: 0px 0px 5px 0px rgba(17, 17, 26, 0.18);
			}

			&.is-active{
				a{
					&::before{
						@include g.mq('lg') {
							position: static;
						}
					}
				}
			}
			
			#frontpage &{
				a{
					box-shadow: none;
				}
			}
		}

		&.is-recruit{
			@include g.mq('lg-max') {
				> a{
					position: relative;
					pointer-events: none;

					&::after{
						content: "";
						position: absolute;
						top: 0;
						bottom: 5px;
						right: 15px;
						margin: auto;
						@include g.arrow($type: 'bottom', $size: 0.8em, $weight: 1px, $color: g.color('base'), $round: false, $skew: false);
						transition: .4s;
					}
				}
			}
			

			&.isOpenAccordion{
				@include g.mq('lg-max') {
					> a{
						&::after{
							bottom: -5px;
							transform: rotate(45deg);
						}
					}
				}
			}
		}
	}

	&__submenu{
		display: block; // 常にblockにしておき、visibilityで表示制御
		overflow: hidden;
		transition: height 0.6s;
		
		@include g.mq('lg') {
			opacity: 0; // 初期状態では透明にしておく
			visibility: hidden; // 初期状態では非表示にしておく
			position: absolute;
			overflow: auto;
			top: 100%; // 親要素の下端から開始
			left: 0;
			z-index: 100;
			transition: opacity 0.4s, visibility 0.4s, transform 0.4s; // opacity, visibility, transformに対してトランジションを適用
			transform: translateY(-10px); // 初期状態では少し上にずらしておく
		}

		&-list{
			min-width: 177px;
			background: #f6f8f7;
			padding: 10px;
			border-radius: 12px;
		}

		&-item{
			&:not(:last-of-type){
				border-bottom: 1px solid g.color('border');
			}
			a{
				display: block;
				padding: 10px 5px;
				font-size: 1.3rem;
				font-family: g.font('tit');
				line-height: 1.3;
				
				span{
					display: block;
					font-size: 1.0rem;
					font-family: g.font('base');
					color: g.color('key');
				}
			}
		}
		
	}

	&__item:hover &__submenu{
		@include g.mq('lg') {
			opacity: 1; // ホバー時に透明度を1にする
			visibility: visible; // ホバー時に表示する
			transform: translateY(0); // ホバー時に位置を初期状態に戻す
		}
	}
}