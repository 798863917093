@use "../global" as g;

/*------------------------------------------
	コンボ：棒・折れ線グラフ
------------------------------------------*/
.bar-line {
    position: relative;
    width: 100%;
    margin: 200px auto 0;
    
    @include g.mq('md') {
        width: 300px;
        margin: 0 auto;
    }

    @include g.mq('lg') {
        width: g.clamp_size(300,404);
    }

    img{
        position: absolute;
        top: -180px;
        right: 0;
        left: 0;
        margin: auto;

        @include g.mq('md') {
            top: -160px;
        }

        @include g.mq('lg') {
            top: calc(g.clamp_size(120,90) * -1);
        }
    }
}