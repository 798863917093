@use "../global" as g;

/*------------------------------------------
	tit
------------------------------------------*/
.tit {
	margin-bottom: 35px;
	font-family: g.font('tit');
	font-size: 4.2rem;
	font-weight: bold;
	text-align: center;
	color: g.color('key');
	line-height: 1.2;
	
	@include g.mq('md') {
		font-size: 7.0rem;
	}

	span{
		display: block;
		font-size: 1.4rem;
		color: g.color('base');
		
		@include g.mq('md') {
			font-size: 1.5rem;
		}
	}
}

/*------------------------------------------
	tit
------------------------------------------*/
.tit {
	margin-bottom: 35px;
	font-family: g.font('tit');
	font-size: 3.4rem;
	font-weight: bold;
	line-height: 1.2;
	
	@include g.mq('md') {
		font-size: 6.7rem;
	}

	span{
		display: block;
		font-size: 2.2rem;
		color: g.color('base');
		
		@include g.mq('md') {
			font-size: 1.8rem;
		}
	}
}


