@use "../global" as g;
/*------------------------------------------
	tit03
------------------------------------------*/
.tit03 {
	position: relative;
	padding-bottom: 15px;
	margin-bottom: 15px;
	font-size: 1.6rem;
	font-weight: 500;
	
	@include g.mq('md') {
		font-size: 2.2rem;
	}

	&::after {
		content: "";
		display: block;
		width: 42px;
		height: 2px;
		background: #B3FF5E;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}


