@use "../global" as g;

/*------------------------------------------
	円グラフ
------------------------------------------*/
.pie {
    position: relative;
    width: 224px;
    margin: 0 auto;

    img{
        position: absolute;
        max-width: initial;
    }

    &.is-01{
        margin-top: 30px;
        
        @include g.mq('md') {
            margin-top: 0;
        }

        img{
            top: -34px;
            left: -18px;
        }
    }

    &.is-02{
        margin-top: 10px;
        
        @include g.mq('md') {
            margin-top: 0;
        }

        img{
            top: -15px;
            left: -62px;
        }
    }

    &.is-03{
        img{
            bottom: 27px;
            left: 37px;
        }
    }

    &.is-04{
        
        @include g.mq('md') {
            margin-top: 50px;
        }

        img{
            bottom: 45px;
            left: 37px;
        }
    }

    &.is-05{
        margin-top: 0;
        
        @include g.mq('md') {
            margin-top: 36px;
        }

        img{
            bottom: 46px;
            left: 29px;
        }
    }
}