@use "../global" as g;

/*------------------------------------------
	entry
------------------------------------------*/
.entry{
    position: relative;
    height: 381px;
    background: url(../img/frontpage/entry_bg_sp.webp) no-repeat center / cover;
    
    @include g.mq('md') {
		background: url(../img/frontpage/entry_bg_pc.webp) no-repeat center / auto 100%;
    }
	
	#frontpage &{
		margin-top: -67px;
		
		@include g.mq('md') {
			margin-top: -82px;
		}
	}

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #29423C;
        mix-blend-mode: multiply;
    }

    .inner{
        height: 100%;
    }

    &__box{
        position: relative;
        height: 100%;
        z-index: 1;
        
        @include g.mq('md') {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__tit{
        padding-top: 130px;
        margin-bottom: 60px;
        color: #FFF;
        font-size: 2.1rem;
        text-align: center;
        
        @include g.mq('md') {
            padding-top: 0;
            margin-bottom: 0;
            font-size: 2.4rem;
            text-align: left;
        }
    }

    &__btn{
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: #FFF;
            font-size: 1.8rem;
            
            @include g.mq('md') {
                justify-content: flex-start;
                font-size: 1.5rem;
            }

            &::after{
                content: "";
                position: relative;
                width: 37px;
                height: 37px;
                background: url(../img/common/arrow_w.svg) no-repeat center / contain;
                
                @include g.mq('md') {
                    width: 52px;
                    height: 52px;
                }
            }
        }
    }
}