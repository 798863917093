@use "../global" as g;

/*------------------------------------------
	フッターグロナビ
------------------------------------------*/
.fnav {
	display: none;
	margin-top: 50px;
	
	@include g.mq('lg') {
		display: block;
	}
	
	&__list {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 30px;
	}

	&__item {
		@include g.mq('lg-max') {
			border-top: 1px solid g.color('border');
		}

		&:last-of-type {
			@include g.mq('lg-max') {
				border-bottom: 1px solid g.color('border');
			}
		}

		a {
			display: block;
			font-size: 1.5rem;
			letter-spacing: 0.2em;

			@include g.mq('lg-max') {
				padding: 10px 0;
			}

			@include g.mq('lg') {
				font-size: 1.3rem;
			}
		}

		&.is-active{
			a{
				position: relative;

				&::before{
					content: "";
					position: absolute;
					bottom: -10px;
					right: 0;
					left: 0;
					width: 5px;
					height: 5px;
					margin: auto;
					background: g.color('key');
					border-radius: 10px;
				}
			}
		}
	}
}