/*******************************************
    contact
*******************************************/
@use "../global" as g;

#privacy {
    
    .sec {
        $this: '.sec';

        padding: 60px 0 60px;
        background: #fff;
        font-weight: normal;
        
        @include g.mq('md') {
            padding: 0;
            background: none;
        }

        h3{
            font-weight: normal;
        }
        
        &__wrap {
            position: relative;
            z-index: 1;

            @include g.mq('md') {
                background: #fff;
                margin: -54px auto 40px;
                padding: 90px g.clamp_size(34,100,768,1400);
                border-radius: 39.5px;
                gap: g.clamp_size(10,120,768,1400);
            }
        }

        &__box{
            margin-bottom: 30px;
            
            @include g.mq('md') {
                margin-bottom: 60px;
            }
        }

        &__head{
            margin-bottom: 15px;
            
            @include g.mq('md') {
                margin-bottom: 30px;
            }
        }
        
        &__area{

            p{
                margin-bottom: 10px;
                
                @include g.mq('md') {
                    margin-bottom: 20px;
                }
            }

            ol,ul{
                margin-bottom: 15px;
                padding-left: 2em;
                
                @include g.mq('md') {
                    margin-bottom: 30px;
                }
            }

            ul{
                list-style-type: circle;
            }

            li{
                margin-bottom: 10px;
                
                @include g.mq('md') {
                    margin-bottom: 20px;
                }
            }
        }

        aside{
            text-align: right;
        }
    }
}