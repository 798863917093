/*******************************************
    トップページ
*******************************************/
@use "../global" as g;

#company {

    .sec {
        $this: '.sec';

        padding: 60px 0 60px;
        background: #fff;

        @include g.mq('md') {
            padding: g.clamp_size(70,172,768,1400) 0;
            background: none;
        }

        &.is-access {
            background: none;
            padding-bottom: 0;

            @include g.mq('md') {
                padding: 0;
            }

            #{$this}__wrap {
                @include g.mq('md') {
                    padding-top: 62px;
                    padding-bottom: 73px;
                }
            }
        }

        &.is-origin{
            padding-bottom: 0;
        }
        
        &__wrap {
            position: relative;
            z-index: 1;

            @include g.mq('md') {
                display: flex;
                flex-wrap: wrap;
                background: #fff;
                padding: 90px g.clamp_size(34,100,768,1400);
                border-radius: 39.5px;
                gap: g.clamp_size(10,120,768,1400);
            }
        }

        &__box {
            @include g.mq('md') {
                min-width: 605px;
                flex: 1;
            }
        }

        &__tit {
            margin-bottom: 40px;

            @include g.mq('md') {
                width: 270px;
            }
        }
    }

    .about {
        border-top: 1px solid g.color('border-table');

        @include g.mq('md') {
            margin-top: 10px;
        }

        dl {
            padding: 16px 6px;
            border-bottom: 1px solid g.color('border-table');
            font-size: 1.4rem;

            @include g.mq('md') {
                padding: 26px 6px;
                display: flex;
                font-size: 1.8rem;
            }
        }

        dt {
            color: g.color('key');
            letter-spacing: .18em;
            margin-bottom: 4px;
            
            @include g.mq('md') {
                width: 164px;
                margin-bottom: 0;
            }
        }

        dd {
            letter-spacing: .14em;
            line-height: 2.055;

            @include g.mq('md') {
                flex: 1;
            }
            
            a{
                text-decoration: underline;
            }
        }

        &__list {

            li {
                
                &::before {
                    content: "・";
                    padding-left: .7em;
                    color: g.color('key');
                }
            }
        }
    }

    .access {

        &__tit {
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            font-weight: bold;
            letter-spacing: .2em;
            margin-bottom: 40px;

            @include g.mq('md') {
                gap: 23px;
                font-size: 2.4rem;
                padding-bottom: 24px;
                margin-top: 13px;
                margin-bottom: 30px;
                border-bottom: 1px solid g.color('border-table');
                justify-content: flex-start;
            }

            &::before {
                content: "";
                display: block;
                width: 23px;
                height: 28px;
                background: url(/assets/img/company/icon_train.svg) no-repeat center center/contain;

                @include g.mq('md') {
                    width: 27px;
                    height: 33px;
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            @include g.mq('md') {
                gap: 17px;
            }
        }

        &__item {
            background: #fff;
            border-radius: 10px;
            padding: 20px 7.5%;
            width: 100%;

            @include g.mq('md') {
                flex: 1;
                background: #f6f8f7;
                padding: 36px 14px;
            }
        }

        &__from {
            font-size: 1.4rem;
            color: g.color('key');
            line-height: 1.77;
            letter-spacing: .14em;
            margin-bottom: 9px;
            text-align: center;

            @include g.mq('md') {
                font-size: 1.8rem;
            }
        }

        &__time {
            font-size: 1.8rem;
            letter-spacing: .14em;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            @include g.mq('md') {
                font-size: 2.3rem;
            }

            span {
                font-size: 2.0rem;
                padding: 0 4px;

                @include g.mq('md') {
                    font-size: 3.2rem;
                }
            }
        }

        &__map {
            width: 100%;
            height: 400px;
            margin: 60px auto 0;

            @include g.mq('md') {
                height: 488px;
                margin-top: -50px;
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    .origin{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        
        @include g.mq('md') {
            flex-direction: row;
            gap: g.clamp_size(50,100);
        }

        &__tit{
            color: #00A56E;
            font-size: 2rem;
            line-height: 2;
            letter-spacing: 0.2em;
            font-weight: 400;
            margin-bottom: 10px;

            @include g.mq('md') {
                font-size: 3.4rem;
                line-height: 1.2;
            }
        }

        &__img{
            flex: 0 0 auto;

            @include g.mq('sm') {
                width: g.get_vw(220);
            }

            @include g.mq('md') {
               width: g.clamp_size(260,354);
            }
        }
    }
}

