@use "../global" as g;

/*------------------------------------------
	ハンバーガーメニュー
------------------------------------------*/
.menubar {
	position: relative;
	z-index: map-get(g.$z-index, 'header');
	display: inline-block;
	width: 44px;
	height: 12px;
	margin-right: 30px;

	@include g.mq('lg') {
		display: none;
	}

	span {
		$paddingY: 10px; //バーの間隔をここで調整
		position: absolute;
		left: 0;
		display: inline-block;
		width: 44px;
		height: 2px;
		background-color: g.color('base');
		transition: all .4s;

		&:nth-of-type(1) {
            top: 0;
			width: 44px;
        }

        &:nth-of-type(2) {
			width: 30px;
			bottom: 0;
        }
	}
}