@use "../global" as g;
/*------------------------------------------
	card
------------------------------------------*/
.card {
	padding: 60px 0;
	background-color: #FFF;
	
	@include g.mq('md') {
		padding: 130px 0;
	}
	
	+ .card{
		padding: 0 0 60px;
		
		@include g.mq('md') {
			padding: 0 0 130px;
		}
	}

	&__tit{
		margin-bottom: 60px;
	}

	&__list{
		display: grid;
		gap: 10px;
		
		@include g.mq('md') {
			gap: 25px;
		}

		&.is-col-4{
			@include g.mq('md') {
				grid-template-columns: repeat(3, 1fr);
			}
			
			@include g.mq('lg') {
				grid-template-columns: repeat(4, 1fr);
			}
			
			@include g.mq('xl') {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		&.is-col-5{
			@include g.mq('md') {
				grid-template-columns: repeat(3, 1fr);
			}
			
			@include g.mq('lg') {
				grid-template-columns: repeat(4, 1fr);
			}
			
			@include g.mq('xl') {
				grid-template-columns: repeat(5, 1fr);
			}
		}
	}

	&__item{
		height: 180px;
		
		@include g.mq('md') {
			height: auto;
		}

		a{
			display: block;
			position: relative;
			border-radius: 20px;
	
			&::after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #2B443E;
				opacity: 0.8;
				mix-blend-mode: multiply;
				border-radius: 20px;
			}
		}

		img{
			display: block;
			max-height: 180px;
			width: 100%;
			object-fit: cover;
			border-radius: 20px;
			
			@include g.mq('md') {
				max-height: initial;
			}
		}

		&.is-active{
			a{
				opacity: 0.4;
			}
		}
	}

	&__item-tit{
		position: absolute;
		top: 20px;
		left: 20px;
		font-size: 1.8rem;
		color: #FFF;
		font-weight: bold;
		font-family: g.font('tit');
		z-index: 1;
		letter-spacing: 0.06em;
		line-height: 1.4;
		
		@include g.mq('md') {
			font-size: 2.2rem;
		}
		
		span{
			display: block;
			margin-top: 3px;
			font-size: 1.3rem;
			font-family: g.font('base');
			color: g.color('key-thin');
			
			@include g.mq('md') {
				font-size: 1.6rem;
			}
		}
	}
	
	&__btn{
		position: absolute;
		bottom: 20px;
		left: 20px;
		z-index: 1;
	}
}


