/*------------------------------------------
    CONTACT, ENTRY
------------------------------------------*/
@use "../global" as g;

.contact-sec {
    $this: '.contact-sec';

    padding: 60px 0 60px;
    background: #fff;
    
    @include g.mq('md') {
        padding: 0;
        background: none;
    }
    
    &__wrap {
        position: relative;
        z-index: 1;

        @include g.mq('md') {
            background: #fff;
            margin: -54px auto 40px;
            padding: 90px g.clamp_size(34,100,768,1400);
            border-radius: 39.5px;
            gap: g.clamp_size(10,120,768,1400);
        }
    }

    &__intro{
        margin-bottom: 30px;
        
        &-txt{
            font-size: 1.4rem;
            
            @include g.mq('md') {
                font-size: 1.8rem;
            }
            
            a{
                text-decoration: underline;
                color: g.color('key');
            }
        }
    }
}