// ブレイクポイント
$breakpoints: (
    'xs': 'screen and (max-width: 375px)',
    'sm': 'screen and (max-width: 767px)',
    'md': 'screen and (min-width: 768px)',
    'lg': 'screen and (min-width: 992px)',
    'lg-max': 'screen and (max-width: 991px)',
    'xl': 'screen and (min-width: 1320px)',
    'xl-max': 'screen and (max-width: 1319px)',
)!default;

// inner-width
$inner-width: 1440px;
$inner-width-small: 1240px;
$inner-width-sp: 85%;

// フォント
// 出力例) font('base');
$font: (
    'base': ("Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif),
    'tit': (din-2014, sans-serif),
);

// 色管理
// 出力例) color('base');
// 多次元mapの出力例) color('cat', 'event', 'performance');
$color: (
	'base': #1A1D1A,
    // 'base-thin': #4d4d4d,
    'key-thin': #B3FF5E,
    'key': #00A56E,
    'key-thick': #2B443E,
    'bg': #F0F2F1,
    'bg-thick': #C6C6C6,
    'border': #E0E0E0,
    'border-thick': #C6C6C6,
    'border-table': #e8e8e8,
    'border-tit': #dcdcdc,
    'notice': #e31c24,
    // 'key-thin': #82BC3F,
    // 'accent': #42210B,
    // 'border': #D6E7DF,
);

// 重ね順管理
$z-index: (
    'header': 1000,
    'gotop': 999,
);

//ヘッダーの高さ
$headerH: (
    'sm': 90px,
    'md': 180px
);